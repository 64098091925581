import {
  VUEX_ASSORTMENT_GRID_ROWS_ADD,
  VUEX_ASSORTMENT_GRID_ROWS_DELETE,
  VUEX_ASSORTMENT_GRID_ROWS_UPDATE,
  // VUEX_ASSORTMENT_GRID_ROWS_UPDATE_SORT,
  VUEX_ASSORTMENT_GRID_COLUMNS_UPDATE,
  VUEX_ASSORTMENT_GRID_SETTINGS_UPDATE,
  VUEX_ASSORTMENT_GRID_TREEROWS_ADD,
  VUEX_ASSORTMENT_GRID_TREEROWS_DELETE,
  VUEX_ASSORTMENT_GRID_TREEROWS_UPDATE,
  VUEX_ASSORTMENT_GRID_SET_SNAPSHOT,
  VUEX_ASSORTMENT_PRODUCTS_RENAME,
  VUEX_ASSORTMENT_INTERNAL_INTERNATONAL_CATEGORIES_ADD
} from '@/store/constants/models/assortments'

import {
  VUEX_SEASON_UPDATE,
  VUEX_SEASON_DELETE
} from '@/store/constants/models/seasons'

import { generateInternalAssortmentName } from '@/helpers/assortmentHelper'
import {
  VUEX_COLLECTION_PRODUCT_UPDATE_PRODUCTS_PROMPT
} from '@/store/constants/models/collections'

import {
  VUEX_LIBRARIES_GRID_ROWS_UPDATE,
  VUEX_LIBRARIES_GRID_ROWS_DELETE
} from '@/store/constants/models/libraries'

import {
  VUEX_SAMPLE_INVENTORY_GRID_ROWS_UPDATE,
  VUEX_SAMPLE_INVENTORY_GRID_ROWS_DELETE
} from '@/store/constants/models/samples'

import {
  VUEX_ORDERS_DETAIL_GRID_ROWS_UPDATE,
  VUEX_ORDERS_DETAIL_GRID_ROWS_DELETE,
  VUEX_ORDERS_DETAIL_GRID_ROWS_ADD,
  VUEX_ORDERS_LIST_GRID_ROWS_DELETE,
  VUEX_ORDERS_GRID_SET_SNAPSHOT
} from '@/store/constants/models/orders'

import cloneDeep from 'lodash/cloneDeep'
import GridHelpers from '@/components/_core/GridsCore/helpers/GridHelpers'
import ColumnHelpers from './ColumnHelpers'
import ExcelMiddleware from './ExcelMiddleware'

import shared from 'skch_its_be_fe_shared'

import router from '@/router'
import longWaitBlocker from '@/helpers/longWaitBlocker'
import { VUEX_PRODUCTS_FETCH } from '@/store/constants/models/products'
import feConstants from '@/store/constants/config/fe_constants'
import properties from '@/store/modules/config/properties'

import Debug from 'logdown'
import app from '@/main'
import PropertiesLookupLists from '@/components/_core/GridsCore/helpers/PropertiesLookupLists'

const pricesheet = shared.pricesheet
const d = new Debug('its:aggrid:DataMiddleware')

let DataMiddleware = {
  // variables
  changeHistory: [],
  redoHistory: [],
  oldColumnsArr: [],
  changeHistoryDebouncerTimer: null,
  changeHistoryDebouncerArray: [],
  hierarchySeparator: '_____',

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // config
  isUndoRedo: function (doReverse) {
    return (doReverse === 'redo' || doReverse === 'undo')
  },
  clearHistories: function () {
    DataMiddleware.changeHistory = []
    DataMiddleware.redoHistory = []
    DataMiddleware.oldColumnsArr = []
    DataMiddleware.toggleUndoRedo()
  },
  blockAPICompletely: function () {
    return (GridHelpers.mgThisArray[0].type === 'import-spreadsheet-grid')
  },
  hasUndoElements: function () {
    return (DataMiddleware.changeHistory.length > 0)
  },
  hasRedoElements: function () {
    return (DataMiddleware.redoHistory.length > 0)
  },

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // HISTORY
  addNewChange: function (payload, type) {
    // add to history and debouncing?
    let doHistory = true
    if (
      type === 'columnsUpdate' ||
      type === 'settingsUpdate' ||
      type === 'rowsDelete' ||
      type === 'rowsAdd' ||
      type === 'sortableListSortUpdate' ||
      type === 'rowsRename'
    ) {
      doHistory = false
    }

    // update obj
    let obj = {
      type: type,
      history: true,
      payload: payload
    }
    // add special stash
    if (type === 'rowsUpdateSort') {
      let tmp = GridHelpers.mgThisArray[0].preredrawSnapshot
      obj.preredrawSnapshot = tmp
    }

    clearTimeout(this.changeHistoryDebouncerTimer)
    this.changeHistoryDebouncerArray.push(obj)
    this.changeHistoryDebouncerTimer = setTimeout(function () {
      this.addNewChangeDebounced(doHistory)
    }.bind(this), 100)

    // pass through history or not?
    /*
    if (doHistory) {
      clearTimeout(this.changeHistoryDebouncerTimer)
      this.changeHistoryDebouncerArray.push(obj)
      this.changeHistoryDebouncerTimer = setTimeout(function () {
        this.addNewChangeDebounced(doHistory)
      }.bind(this), 100)
    } else {
      let arr = [obj] // expecting array
      this.apiSwitchboard(arr, '')
    }
     */
  },
  addNewChangeDebounced: function (doHistory) {
    // ping back end with latest batch
    this.apiSwitchboard(this.changeHistoryDebouncerArray, '')

    if (doHistory) {
      this.redoHistory = []
      this.changeHistory.push(this.changeHistoryDebouncerArray)
      DataMiddleware.toggleUndoRedo()
    }

    this.changeHistoryDebouncerArray = []
  },
  undoLastChange: function () {
    if (this.changeHistory.length > 0) {
      // grab last object
      let undoItem = this.changeHistory.pop()

      // add to redo history
      this.redoHistory.push(undoItem)
      DataMiddleware.toggleUndoRedo()

      // now send it through the ringer
      this.apiSwitchboard(undoItem, 'undo')
    }
  },
  redoLastChange: function () {
    if (this.redoHistory.length > 0) {
      // grab last object
      let redoItem = this.redoHistory.pop()

      // add to redo history
      this.changeHistory.push(redoItem)
      DataMiddleware.toggleUndoRedo()

      // now send it through the ringer
      this.apiSwitchboard(redoItem, 'redo')
    }
  },
  toggleUndoRedo: function () {
    if (GridHelpers.isGridReady()) {
      GridHelpers.mgThisArray[0].togglerUndoRedo = Math.random()
    }
  },

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // INTERACT WITH BACKEND API, EMIT TO FRONT END
  apiSwitchboard: function (arr, doReverse) {
    // send each request through, unless it is rowsUpdate
    // rowsUpdate can be better batched.  so group them all into an array and send them through after
    const t = GridHelpers.mgThisArray[0]
    let rowsUpdateBatch = []
    let rowsRenameBatch = []
    for (let i = 0; i < arr.length; i++) {
      let obj = arr[i]
      switch (obj.type) {
        case 'rowsRename':
          rowsRenameBatch.push(obj.payload)
          break
        case 'rowsUpdate':
          rowsUpdateBatch.push(obj.payload)
          break
        case 'rowsUpdateSort':
          this.apiRowsUpdateSort(obj.payload, doReverse, obj.preredrawSnapshot)
          break
        case 'rowsUpdateTreeDataSort':
          this.apiRowsUpdateTreeDataSort(obj.payload, doReverse)
          break
        case 'rowsDelete':
          if (t.isLibrariesGrid) {
            if (doReverse === 'undo') {
              this.apiRowsAddGeneral(obj.payload, doReverse, 'libraries')
            } else {
              this.apiRowsDeleteGeneral(obj.payload, doReverse, 'libraries')
            }
          } else if (t?.type === 'sample-inventory-grid' || t?.type === 'orders-detail' || t?.type === 'orders-list') {
            if (doReverse === 'undo') {
              this.apiRowsAddGeneral(obj.payload, doReverse, t?.type)
            } else {
              this.apiRowsDeleteGeneral(obj.payload, doReverse, t?.type)
            }
          } else {
            if (doReverse === 'undo') {
              this.apiRowsAdd(obj.payload, doReverse)
            } else {
              this.apiRowsDelete(obj.payload, doReverse)
            }
          }

          break
        case 'rowsAdd':
          if (t.isLibrariesGrid) {
            if (doReverse === 'undo') {
              this.apiRowsDeleteGeneral(obj.payload, doReverse, 'libraries')
            } else {
              this.apiRowsAddGeneral(obj.payload, doReverse, 'libraries')
            }
          } else if (t?.type === 'sample-inventory-grid' || t?.type === 'orders-detail' || t?.type === 'orders-list') {
            if (doReverse === 'undo') {
              this.apiRowsDeleteGeneral(obj.payload, doReverse, t?.type)
            } else {
              this.apiRowsAddGeneral(obj.payload, doReverse, t?.type)
            }
          } else {
            if (doReverse === 'undo') {
              this.apiRowsDelete(obj.payload, doReverse)
            } else {
              this.apiRowsAdd(obj.payload, doReverse)
            }
          }
          break
        case 'columnsUpdate':
          this.apiColumnsUpdate(obj.payload)
          break
        case 'settingsUpdate':
          this.apiSettingsUpdate(obj.payload)
          break
        case 'sortableListSortUpdate':
          this.apiSortableListSortUpdate(obj.payload)
          break
      }
    }

    // batch update rowsUpdate
    if (rowsUpdateBatch.length > 0) {
      if (t.type === 'assortments-list') {
        if (t.subtype === 'internal') {
          // InternalAssortmentsListGrid
          this.apiSortableListRowsUpdate(rowsUpdateBatch, doReverse)
        } else if (t.subtype === 'seasons-manager') {
          // Seasons manager Grid
          this.apiSeasonsManagerRowsUpdate(rowsUpdateBatch, doReverse)
        } else {
          // MasterGrid - Tree Data mode
          this.apiRowsUpdateTreeData(rowsUpdateBatch, doReverse)
        }
      } else if (t.isLibrariesGrid) {
        // LibrariesGrid
        this.apiRowsUpdateGeneral(rowsUpdateBatch, doReverse, 'libraries')
      } else if (t?.type === 'sample-inventory-grid' || t?.type === 'orders-detail') {
        // General
        this.apiRowsUpdateGeneral(rowsUpdateBatch, doReverse, t?.type)
      } else {
        // MasterGrid - Assortment Details mode
        this.apiRowsUpdate(rowsUpdateBatch, doReverse)
      }
    }// rowsUpdateBatch

    if (rowsRenameBatch.length > 0) {
      this.apiRowsRename(rowsRenameBatch, doReverse)
    }
  },

  // final actions
  apiRowsRename: function (arr, doReverse) {
    let params = []
    let emitParams = []

    // loop through each row, format, and send them through
    for (let i = 0; i < arr.length; i++) {
      let obj = arr[i]
      if (obj.data) {
        // check if already added ID, if so, append ID
        let existingObj = params.filter(params => params?.data?.styleColorId === obj.data.styleColorId)
        let newobj = null
        let theValue = (doReverse === 'undo') ? obj.oldValue : obj.newValue
        // let theOldValue = (doReverse === 'undo') ? obj.newValue : obj.oldValue

        if (existingObj.length === 0) {
          // set all defaults here
          let styleColorFromId = obj.data.id.split('-')
          newobj = {
            id: obj.data.id,
            style: styleColorFromId[0],
            color: styleColorFromId[1],
            newStyle: obj.data.style,
            newColor: obj.data.color
          }
          params.push(newobj)
          emitParams.push(newobj)
        } else {
          newobj = existingObj[0]
        }

        // override here
        if (obj.colDef.field === 'color') {
          newobj.newColor = theValue
        } else {
          newobj.newStyle = theValue
        }
      }
    }

    if (params.length > 0) {
      if (DataMiddleware.blockAPICompletely() === false) {
        GridHelpers.mgThisArray[0].$store.dispatch(VUEX_ASSORTMENT_PRODUCTS_RENAME, params)
      }
    }

    if (DataMiddleware.isUndoRedo(doReverse)) {
      app.config.globalProperties.emitter.emit('aggrid-event', {
        type: 'rowsRename',
        history: doReverse,
        payload: emitParams
      })
    }
  },
  apiRowsDelete: function (arr, doReverse) {
    let params = []
    let emitParams = []

    for (let i = 0; i < arr.length; i++) {
      let obj = arr[i]
      let newobj = {}
      if (GridHelpers.mgThisArray[0].type === 'assortments-list') {
        newobj = {
          id: obj.id,
          type: obj.type,
          title: obj.title
        }
      } else {
        newobj = {
          id: obj.id,
          style: obj.style,
          color: obj.color
        }
      }

      // dont send treedata folders to back end API
      let pushIt = true
      if (GridHelpers.mgThisArray[0].type === 'assortments-list') {
        if (obj.type === 'folder') {
          pushIt = false
        }
      }
      if (pushIt) {
        params.push(newobj)
      }
      emitParams.push(newobj)
    }
    if (params.length > 0) {
      if (DataMiddleware.blockAPICompletely() === false) {
        if (GridHelpers.mgThisArray[0].subtype === 'seasons-manager') {
          GridHelpers.mgThisArray[0].$store.dispatch(VUEX_SEASON_DELETE, params).then(() => {
          })
        } else if (GridHelpers.mgThisArray[0].type === 'assortments-list') {
          GridHelpers.mgThisArray[0].$store.dispatch(VUEX_ASSORTMENT_GRID_TREEROWS_DELETE, params).then(() => {
          })
        } else {
          GridHelpers.mgThisArray[0].$store.dispatch(VUEX_ASSORTMENT_GRID_ROWS_DELETE, params)

          // collections - no more collections delete
          // GridHelpers.mgThisArray[0].$store.dispatch(VUEX_COLLECTION_PRODUCT_REMOVE_PRODUCTS, params).then(() => {})
        }
      }
    }

    if (DataMiddleware.isUndoRedo(doReverse)) {
      app.config.globalProperties.emitter.emit('aggrid-event', {
        type: 'rowsDelete',
        history: doReverse,
        payload: emitParams
      })
    }
  },
  apiRowsAdd: function (arr, doReverse) {
    let params = []
    let emitParams = []

    for (let i = 0; i < arr.length; i++) {
      let obj = arr[i]
      let newobj = {}
      Object.assign(newobj, obj)

      newobj = DataMiddleware.presubmitObjectMassaging(newobj, 'apiRowsAdd')

      // dont send treedata folders to back end API
      let pushIt = true
      if (GridHelpers.mgThisArray[0].type === 'assortments-list') {
        if (newobj.type === 'folder') {
          pushIt = false
        }
      }
      if (pushIt) {
        params.push(newobj)
      }
      emitParams.push(newobj)
    }

    if (params.length > 0) {
      if (DataMiddleware.blockAPICompletely() === false) {
        if (GridHelpers.mgThisArray[0].type === 'assortments-list') {
          GridHelpers.mgThisArray[0].$store.dispatch(VUEX_ASSORTMENT_GRID_TREEROWS_ADD, params).then(() => {
          })
        } else {
          GridHelpers.mgThisArray[0].$store.dispatch(VUEX_ASSORTMENT_GRID_ROWS_ADD, params).then(() => {
          })

          // WE NEED TO RESORT THE ORDER FOR FORCED SORTING
          let rowNodes = GridHelpers.getRowNodes()
          let retData = GridHelpers.extractRowDataFromRowNodes(rowNodes)
          DataMiddleware.sendSortPacketToBackend(retData)
        }
      }
    }

    if (DataMiddleware.isUndoRedo(doReverse)) {
      app.config.globalProperties.emitter.emit('aggrid-event', {
        type: 'rowsAdd',
        history: doReverse,
        payload: emitParams
      })
    }
  },
  apiRowsUpdate: function (arr, doReverse) {
    let additionUpdateHandlers = {
      doUpdate: false,
      selected: [],
      collectionStyleNote: [],
      collectionColorNote: []
    }
    let params = []

    // loop through each row, format, and send them through
    for (let i = 0; i < arr.length; i++) {
      let obj = arr[i]
      if (obj.data) {
        // check if already added ID, if so, append ID
        let existingObj = params.filter(params => params?.data?.styleColorId === obj.data.styleColorId)
        let newobj = null
        let theValue = (doReverse === 'undo') ? obj.oldValue : obj.newValue

        // Special Formatting - RECALCULATING
        // netCost is a really unique column that is both entered and calculated.  so need to do extra cleanup here
        if (obj.colDef.field === 'netCost') {
          theValue = ColumnHelpers.numberRemoveExtraneous(theValue)
          if (theValue === '') {
            theValue = 0
          }
        }

        // generally do an update
        let doTheUpdate = true
        // but don't update if a label no longer exists in available list of labels
        if (obj.colDef.field === 'label' && theValue !== '') {
          doTheUpdate = GridHelpers.colorLabelExists(theValue)
        }
        if (obj.colDef.field === 'selected') {
          // if selected, dont update
          // send to special selected updater
          obj.theValue = (theValue)
          additionUpdateHandlers.selected.push(obj)
          additionUpdateHandlers.doUpdate = true
          doTheUpdate = false
        }
        if (obj.colDef.field === 'collectionStyleNote') {
          // if collectionStyleNote, dont update
          // send to special selected updater
          obj.theValue = theValue
          additionUpdateHandlers.collectionStyleNote.push(obj)
          additionUpdateHandlers.doUpdate = true
          doTheUpdate = false
        }
        if (obj.colDef.field === 'collectionColorNote') {
          // if collectionColorNote, dont update
          // send to special selected updater
          obj.theValue = theValue
          additionUpdateHandlers.collectionColorNote.push(obj)
          additionUpdateHandlers.doUpdate = true
          doTheUpdate = false
        }

        // do update?
        if (doTheUpdate) {
          if (existingObj.length === 0) {
            newobj = {
              id: obj.data.id,
              style: obj.data.style,
              color: obj.data.color,
              properties: {}
            }
            newobj.properties[obj.colDef.field] = theValue
            params.push(newobj)
          } else {
            newobj = existingObj[0]
            newobj.properties[obj.colDef.field] = theValue
          }
        } // do the update

        if (newobj) {
          newobj = DataMiddleware.presubmitObjectMassaging(newobj, 'apiRowsUpdate')
        }
      } // if object data
    } // for each obj

    if (params.length > 0) {
      if (DataMiddleware.blockAPICompletely() === false) {
        GridHelpers.mgThisArray[0].$store.dispatch(VUEX_ASSORTMENT_GRID_ROWS_UPDATE, params).then(() => {
        })
      }
    }

    // special update (reverse handler is in there
    if (additionUpdateHandlers.doUpdate) {
      DataMiddleware.apiRowsUpdateCollection(additionUpdateHandlers, doReverse)
    }

    if (DataMiddleware.isUndoRedo(doReverse)) {
      if (params.length) {
        app.config.globalProperties.emitter.emit('aggrid-event', {
          type: 'rowsUpdate',
          history: doReverse,
          payload: params
        })
      }
    }
  },
  apiRowsUpdateSort: function (arr, doReverse, preredrawSnapshot) {
    // use the snapshot to reverse
    if (doReverse === 'undo') {
      if (preredrawSnapshot && preredrawSnapshot.rowsData) {
        arr = preredrawSnapshot.rowsData
      }
    }

    let emitParams = DataMiddleware.sendSortPacketToBackend(arr)

    // set columns to null
    let columnsArr = []
    this.apiColumnsUpdate(columnsArr)

    if (DataMiddleware.isUndoRedo(doReverse)) {
      app.config.globalProperties.emitter.emit('aggrid-event', {
        type: 'rowsUpdateSort',
        payload: emitParams,
        doHardReset: true,
        history: doReverse,
        preredrawSnapshot: preredrawSnapshot
      })
    }
  },

  // Special Formatting - APPENDING
  presubmitObjectMassaging: function (tobj, apiType) {
    const t = GridHelpers.mgThisArray[0]
    const assortment = t.assortment

    // get the data properties
    let newDataObj = (apiType === 'apiRowsUpdate') ? tobj.properties : tobj

    // if removing a thumbnail, be sure to clear out printImage also
    // you don't need to do this on adding, because that is handled via the whole adding API process
    if (newDataObj.thumbnail === '') {
      newDataObj.printImage = ''
    }

    if (
      newDataObj.hasOwnProperty('pillar') ||
      newDataObj.hasOwnProperty('division') ||
      newDataObj.hasOwnProperty('divisionGroup') ||
      newDataObj.hasOwnProperty('gender')
    ) {
      // if updating pillar to a value, dispatch state update to pillar lookup list - for key initiatives
      if (newDataObj.hasOwnProperty('pillar')) {
        let theValue = newDataObj.pillar
        if (theValue) {
          // then also set displayGroup
          const tpayload = {
            assortment: assortment,
            item: theValue
          }
          t.$store.dispatch(VUEX_ASSORTMENT_INTERNAL_INTERNATONAL_CATEGORIES_ADD, tpayload)
        }// if (theValue)
      }

      // now also set displayGroup
      // check groupBy value from uiSettings
      let newProperty = (assortment.uiSettings?.groupSettings?.groupBy?.value) ? assortment.uiSettings?.groupSettings?.groupBy?.value : ''
      // if blank, groupSettings has not yet been set - so check from properties default
      if (newProperty === '') {
        let isInternalAssortment = (assortment?.orgType === ITS__ASSORTMENTS__ORG_TYPE__INTERNAL)
        if (isInternalAssortment) {
          newProperty = properties.state.data?.GroupSort?.Internal?.groupBy?.value || ''
        }
      }
      // if not blank and also if the property is set in the newDataObj
      if (newProperty) {
        if (newDataObj.hasOwnProperty(newProperty)) {
          let lookupobj = {}
          lookupobj[newProperty] = newDataObj[newProperty]
          newDataObj.displayGroup = pricesheet.getDisplayGroupValueFromSegment(lookupobj, assortment, properties)
        }
      }
    }

    /*
    //add locations code to KI
    if (router.currentRoute?.value.meta?.manageType === ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__INTERNAL) {
      let region = t.assortment?.hierarchy[0]
      if (!newDataObj.locations) {
        newDataObj.locations = {}
      }
      if (region === 'Europe') {
        newDataObj.locations.code = 'EU'
      } else {
        newDataObj.locations.code = 'US'
      }
    }
    */

    // Special Formatting - APPENDING
    if (apiType === 'apiRowsUpdate') {
      tobj.properties = newDataObj
      return tobj
    } else {
      return newDataObj
    }
  },

  // createSortPacketForBackend gets current sort order, to be used for sendSortPacketToBackend
  // only for client side data
  // since we dont save sort packets with server side
  prepAndSendSortPacketForBackend: function (extractChildrenFromGroup = false) {
    /*
    const t = GridHelpers.mgThisArray[0]
    if (t.rowModelType !== 'serverSide') {
      let rowNodes = GridHelpers.getRowNodes(extractChildrenFromGroup)
      let rowsData = GridHelpers.extractRowDataFromRowNodes(rowNodes, extractChildrenFromGroup)
      if (t.type === 'assortments-list' && router.currentRoute.value.meta.manageType === ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__INTERNAL) {
        t.saveRowSort()
      } else {
        DataMiddleware.sendSortPacketToBackend(rowsData)
      }
    }

     */
  },

  apiRowsUpdateCollection: function (additionUpdateHandlers, doReverse) {
    let params = []
    let undoParams = []

    for (let i = 0; i < additionUpdateHandlers.selected.length; i++) {
      let obj = additionUpdateHandlers.selected[i]
      // backend api
      let newobj = {
        style: obj.data.style,
        color: obj.data.color,
        selected: (obj.theValue)
      }
      params.push(newobj)

      // emitted undoer
      let undoobj = {
        id: obj.data.id,
        style: newobj.style,
        color: newobj.color,
        properties: {
          selected: obj.theValue
        }
      }
      undoParams.push(undoobj)
    }
    for (let i = 0; i < additionUpdateHandlers.collectionStyleNote.length; i++) {
      let obj = additionUpdateHandlers.collectionStyleNote[i]
      // backend api
      let newobj = {
        style: obj.data.style,
        color: obj.data.color,
        collectionStyleNote: (obj.theValue) ? obj.theValue : ''
      }
      params.push(newobj)

      // emitted undoer
      let undoobj = {
        id: obj.data.id,
        style: obj.data.style,
        color: obj.data.color,
        properties: {
          collectionStyleNote: (obj.theValue) ? obj.theValue : ''
        }
      }
      undoParams.push(undoobj)
    }
    for (let i = 0; i < additionUpdateHandlers.collectionColorNote.length; i++) {
      let obj = additionUpdateHandlers.collectionColorNote[i]
      // backend api
      let newobj = {
        style: obj.data.style,
        color: obj.data.color,
        collectionColorNote: obj.theValue
      }
      params.push(newobj)

      // emitted undoer
      let undoobj = {
        id: obj.data.id,
        style: obj.data.style,
        color: obj.data.color,
        properties: {
          collectionColorNote: obj.theValue
        }
      }
      undoParams.push(undoobj)
    }

    if (params.length > 0) {
      if (DataMiddleware.blockAPICompletely() === false) {
        GridHelpers.mgThisArray[0].$store.dispatch(VUEX_COLLECTION_PRODUCT_UPDATE_PRODUCTS_PROMPT, params).then(() => {
        })
      }

      app.config.globalProperties.emitter.emit('aggrid-event', {
        type: 'rowsUpdate',
        history: doReverse,
        payload: undoParams
      })
    }
  },

  generateAndSendSortPacketToBackend: function () {
    /*
    let rowNodes = GridHelpers.getRowNodes()
    let retData = GridHelpers.extractRowDataFromRowNodes(rowNodes)
    DataMiddleware.sendSortPacketToBackend(retData)

     */
  },

  // sendSortPacketToBackend is it's own API call outside of history, because sometimes we want it integrated in history via apiRowsUpdateSort
  // or sometimes we just want to call it directly to update the sort order - these are the cases for that:
  //    1) static assortments, column sort - so we click on a column to sort the column, but need to re-arrange the data
  sendSortPacketToBackend: function (arr) {
    /*
    let params = []
    let emitParams = []

    for (let i = 0; i < arr.length; i++) {
      let obj = arr[i]
      let newobj = {
        style: obj.style,
        color: obj.color,
        sort: i
      }
      params.push(newobj)

      // emit needs ID
      let newobjEmit = cloneDeep(obj)
      newobjEmit.id = obj.id
      emitParams.push(newobjEmit)
    }

    if (params.length > 0) {
      if (DataMiddleware.blockAPICompletely() === false) {
        GridHelpers.mgThisArray[0].$store.dispatch(VUEX_ASSORTMENT_GRID_ROWS_UPDATE_SORT, {
          params: params
        }).then(() => {
        })
      }
    }

    return emitParams

     */
  },

  apiRowsUpdateTreeData: function (arr, doReverse) {
    let params = []
    let emitParams = []
    let newobj = null

    for (let i = 0; i < arr.length; i++) {
      let obj = arr[i]

      if (obj.oldValue !== obj.newValue) {
        // get field name that was changed
        let fieldChanged = obj.colDef.field

        if (obj.data.type === 'folder') {
          // IS A FOLDER
          // the only fieldChanged to ever meet this condition is title, but just confirming fieldChanged === 'title'
          if (fieldChanged === 'title') {
            // let thisHierarchy = obj.data.hierarchy.slice(0)
            let oldValue = obj.oldValue
            let newValue = obj.newValue
            // let theValue = (doReverse === 'undo') ? obj.oldValue : obj.newValue

            // replace first instance of hierarchy
            // let oldHierarchy = obj.data.hierarchy.slice(0)
            let theIndexSearchValue = (doReverse === 'undo') ? newValue : oldValue
            let theIndexReplaceValue = (doReverse === 'undo') ? oldValue : newValue

            let idx = obj.data.hierarchy.indexOf(theIndexSearchValue)
            if (idx !== -1) {
              obj.data.hierarchy[idx] = theIndexReplaceValue
              obj.data.title = theIndexReplaceValue
              GridHelpers.mgThisArray[0].gridApi.redrawRows()

              // push through title
              let newobj = {
                id: obj.data.id,
                properties: {
                  title: obj.data.title
                }
              }
              emitParams.push(newobj)
            }

            // upload all children folders
            if (obj.node.allLeafChildren.length > 0) {
              for (let ii = 0; ii < obj.node.allLeafChildren.length; ii++) {
                let obj2 = obj.node.allLeafChildren[ii]

                // replace first instance
                idx = obj2.data.hierarchy.indexOf(theIndexSearchValue)
                if (idx !== -1) {
                  obj2.data.hierarchy[idx] = theIndexReplaceValue
                  if (obj2.data.type === 'item') {
                    // push it to params
                    newobj = {
                      id: obj2.data._id,
                      properties: {}
                    }
                    let hierarchyClone = obj2.data.hierarchy.slice(0)
                    hierarchyClone.pop()
                    newobj.properties['hierarchy'] = hierarchyClone
                    params.push(newobj)
                  }
                }
              }
            }
          }
        } else {
          // NOT A FOLDER
          // check if already added ID, if so, append ID
          let existingObj = params.filter(params => params?.data?.styleColorId === obj.data.styleColorId)
          let theValue = (doReverse === 'undo') ? obj.oldValue : obj.newValue
          if (existingObj.length === 0) {
            newobj = {
              id: obj.data._id,
              properties: {}
            }
            newobj.properties[obj.colDef.field] = theValue
            params.push(newobj)

            emitParams.push(newobj)
          } else {
            newobj = existingObj[0]
            newobj.properties[obj.colDef.field] = theValue
          }
        }
      }// if obj.oldValue !== obj.newValue
    } // loop

    if (params.length > 0) {
      if (DataMiddleware.blockAPICompletely() === false) {
        GridHelpers.mgThisArray[0].$store.dispatch(VUEX_ASSORTMENT_GRID_TREEROWS_UPDATE, params).then(() => {
        })
      }
    }

    if (emitParams.length > 0) {
      // history update
      if (DataMiddleware.isUndoRedo(doReverse)) {
        app.config.globalProperties.emitter.emit('aggrid-event', {
          type: 'treerowsUpdate',
          history: doReverse,
          payload: emitParams
        })
      }

      // if grid synch, update the other synched grids
      if (GridHelpers.mgThisArray[0].gridSynch) {
        app.config.globalProperties.emitter.emit('aggrid-event', {
          type: 'treerowsUpdate',
          history: 'gridSynch',
          payload: emitParams
        })
      }
    }

    // refresh
    // GridHelpers.mgThisArray[0].gridApi.redrawRows()
  },
  apiRowsUpdateTreeDataSort: function (arr, doReverse) {
    let params = []
    let updatedRows = []
    // let didOneItem = false

    // loop through each row, format, and send them through
    // only save items to back end, not folders
    // for history state, only keep track of folders and update all sub items
    for (let i = 0; i < arr.length; i++) {
      let obj = arr[i]
      let oldHierarchyReplacement = obj.hierarchy.slice(0)
      let hierarchyClone = (DataMiddleware.isUndoRedo(doReverse)) ? obj.oldHierarchy.slice(0) : obj.hierarchy.slice(0)
      let hierarchyClone2 = (DataMiddleware.isUndoRedo(doReverse)) ? obj.oldHierarchy.slice(0) : obj.hierarchy.slice(0)
      if (obj.type === 'item') {
        hierarchyClone.pop()
        let newobj = {
          id: obj._id,
          properties: {
            hierarchy: hierarchyClone,
            title: obj.title,
            subTitle: obj.subTitle
          }
        }
        // didOneItem = true
        params.push(newobj)
      }

      // if undoing or redoing - also update the front end
      if (DataMiddleware.isUndoRedo(doReverse)) {
        obj.hierarchy = hierarchyClone2
        obj.oldHierarchy = oldHierarchyReplacement
      }

      // keep a copy of OG for redo/undo
      updatedRows.push(obj)
    }

    if (params.length > 0) {
      if (DataMiddleware.blockAPICompletely() === false) {
        GridHelpers.mgThisArray[0].$store.dispatch(VUEX_ASSORTMENT_GRID_TREEROWS_UPDATE, params).then(() => {
        })
      }
    }

    // upon completion, we need to call applyTransaction if we are updating tree data on a redo or undo
    if (DataMiddleware.isUndoRedo(doReverse)) {
      GridHelpers.mgThisArray[0].gridApi.applyTransaction({ update: updatedRows })
      GridHelpers.mgThisArray[0].gridApi.clearFocusedCell()

      // refresh
      // GridHelpers.mgThisArray[0].gridApi.redrawRows()
    }
  },

  // dont block via DataMiddleware.blockAPICompletely() for columns or settigs
  apiColumnsUpdate: function (columnsArr) {
    columnsArr = DataMiddleware.parseAssortmentSort(columnsArr, false, false)
    GridHelpers.mgThisArray[0].$store.dispatch(VUEX_ASSORTMENT_GRID_COLUMNS_UPDATE, columnsArr).then(() => {
      // done
    })
    GridHelpers.oldColumnsArr = columnsArr
  },
  apiSettingsUpdate: function (settingsArr) {
    GridHelpers.mgThisArray[0].$store.dispatch(VUEX_ASSORTMENT_GRID_SETTINGS_UPDATE, settingsArr).then(() => {
    })
  },

  /// ///////////////////////
  apiRowsUpdateGeneral: function (arr, doReverse, type) {
    let params = []

    // loop through each row, format, and send them through
    for (let i = 0; i < arr.length; i++) {
      let obj = arr[i]
      if (obj.data) {
        // check if already added ID, if so, append ID
        let existingObj = params.filter(params => params['_id'] === obj.data._id)
        let newobj = null
        let field = obj.colDef.field
        let theValue = (doReverse === 'undo') ? obj.oldValue : obj.newValue

        // store value as array if multi
        // convert to array if multiselct
        if (obj.column.colDef && obj.column.colDef.cellEditorParams && obj.column.colDef.cellEditorParams.multipleSelect) {
          if (!theValue) {
            theValue = []
          } else if (Array.isArray(theValue) && theValue.length === 1) {
            theValue = theValue[0].split(',')
          } else if (!Array.isArray(theValue)) {
            theValue = theValue.split(',')
          }
        }

        // why have patch and properties? because properties is used extensively in the code.
        // however, this new api uses patch for the back end
        // so just simpler to put it in twice
        // likewise, same with _id and id
        let doNewObj = (existingObj.length === 0)
        if (doNewObj) {
          let id = obj.data._id
          if (type === 'orders-detail') {
            id = obj.data.identifier
          }

          newobj = {
            _id: id,
            patch: {},

            frontEnd: {
              id: obj.data._id,
              properties: {}
            }
          }
          if (type === 'libraries') {
            newobj.updateFiles = []
          }
        } else {
          newobj = existingObj[0]
        }

        // set values
        // if primaryFile, then don't use patch, use update files
        if (field.indexOf('primaryFile') > -1) {
          let filesField = field.replace('primaryFile.', '')
          let filesObj = {
            _id: obj.data.primaryFile._id,
            properties: {}
          }
          filesObj.properties[filesField] = theValue
          newobj.updateFiles.push(filesObj)
        } else {
          newobj.patch[field] = theValue
        }
        // keep in frontEnd object for optional frontEnd usage
        newobj.frontEnd.properties[field] = theValue

        // add once to params
        if (doNewObj) {
          params.push(newobj)
        }

        // ALSO UPDATE LOOKUP LISTS!
        PropertiesLookupLists.editPropertiesLookupList(obj.colDef.field, theValue)
      }
    }

    if (params.length > 0) {
      if (DataMiddleware.blockAPICompletely() === false) {
        switch (type) {
          case 'sample-inventory-grid':
            GridHelpers.mgThisArray[0].$store.dispatch(VUEX_SAMPLE_INVENTORY_GRID_ROWS_UPDATE, params).then(() => {})
            break
          case 'orders-detail':
            GridHelpers.mgThisArray[0].$store.dispatch(VUEX_ORDERS_DETAIL_GRID_ROWS_UPDATE, params).then(() => {})
            break
          case 'libraries':
          default:
            GridHelpers.mgThisArray[0].$store.dispatch(VUEX_LIBRARIES_GRID_ROWS_UPDATE, params).then(() => {})
            break
        }
      }
    }

    if (DataMiddleware.isUndoRedo(doReverse)) {
      app.config.globalProperties.emitter.emit('aggrid-event', {
        type: 'rowsUpdate',
        history: doReverse,
        payload: params
      })
    }
  },
  apiRowsDeleteGeneral: async function (arr, doReverse, type) {
    let rows = []

    for (let i = 0; i < arr.length; i++) {
      let obj = arr[i]

      let id = obj._id
      if (type === 'orders-detail') {
        id = obj.identifier
      }
      let newobj = {
        frontEnd: {
          id: obj._id,
          properties: {
            status: 'Deleted'
          }
        },
        _id: id,
        patch: {
          status: 'Deleted'
        }
      }
      rows.push(newobj)
    }
    let params = {
      rows: rows
    }

    switch (type) {
      case 'sample-inventory-grid':
        GridHelpers.mgThisArray[0].$store.dispatch(VUEX_SAMPLE_INVENTORY_GRID_ROWS_DELETE, params)
        break
      case 'orders-detail':
        await GridHelpers.mgThisArray[0].$store.dispatch(VUEX_ORDERS_DETAIL_GRID_ROWS_DELETE, params)
        GridHelpers.mgThisArray[0].togglerRefreshButtons = Math.random()
        break
      case 'orders-list':
        await GridHelpers.mgThisArray[0].$store.dispatch(VUEX_ORDERS_LIST_GRID_ROWS_DELETE, params)
        break
      case 'libraries':
      default:
        GridHelpers.mgThisArray[0].$store.dispatch(VUEX_LIBRARIES_GRID_ROWS_DELETE, params)
        break
    }
    // if (DataMiddleware.isUndoRedo(doReverse)) {
    //      app.config.globalProperties.emitter.emit('aggrid-event', { type: 'rowsDelete', history: doReverse, payload: emitParams })
    // }
  },

  apiRowsAddGeneral: async function (arr, doReverse, type) {
    let t = GridHelpers.mgThisArray[0]
    let params = []
    let emitParams = []
    for (let i = 0; i < arr.length; i++) {
      let obj = arr[i]
      let newobj = {}
      Object.assign(newobj, obj)

      delete newobj.dataOnLoad // kill this holder

      params.push(newobj)
      emitParams.push(newobj)
    }

    if (params.length > 0) {
      if (DataMiddleware.blockAPICompletely() === false) {
        if (type === 'orders-detail') {
          await t.$store.dispatch(VUEX_ORDERS_DETAIL_GRID_ROWS_ADD, params)
          t.togglerRefreshButtons = Math.random()
        } else {
          console.error('Data Middleware RowsAddGeneral Type Not Found')
        }
      }
    }

    if (DataMiddleware.isUndoRedo(doReverse)) {
      app.config.globalProperties.emitter.emit('aggrid-event', {
        type: 'rowsAdd',
        history: doReverse,
        payload: emitParams
      })
    }
  },

  // pass new key value for carryover date, then mimic the call to apiSortableListRowsUpdate
  apiSortableListCarryOverDateSpecial: function (newDate, firstAssortmentId = '') {
    if (newDate && firstAssortmentId) {
      const arr = [
        {
          oldValue: null,
          newValue: newDate,
          colDef: {
            field: 'carryoverDate'
          },
          data: {
            _id: firstAssortmentId,
            id: firstAssortmentId
          }
        }
      ]
      DataMiddleware.apiSortableListRowsUpdate(arr, false)
    }
  },

  apiSortableListRowsUpdate: function (arr, doReverse) {
    let params = []
    let emitParams = []
    let newobj = null

    for (let i = 0; i < arr.length; i++) {
      const obj = arr[i]
      if (obj.oldValue !== obj.newValue) {
        // get field name that was changed
        let fieldChanged = obj.colDef.field

        // check if already added ID, if so, append ID
        let existingObj = params.filter(params => params?.data?.styleColorId === obj.data.styleColorId)
        let theValue = (doReverse === 'undo') ? obj.oldValue : obj.newValue

        // special value handling
        // hierarchy.1 needs to be passed as full array
        if (fieldChanged === 'gender') {
          fieldChanged = 'gender'
          theValue = [
            obj.data.region,
            theValue
          ]
        }

        if (existingObj.length === 0) {
          newobj = {
            id: obj.data._id,
            properties: {}
          }
          newobj.properties[fieldChanged] = theValue
          params.push(newobj)

          emitParams.push(newobj)
        } else {
          newobj = existingObj[0]
          newobj.properties[fieldChanged] = theValue
        }
      }// if obj.oldValue !== obj.newValue
    } // loop

    if (params.length > 0) {
      if (DataMiddleware.blockAPICompletely() === false) {
        GridHelpers.mgThisArray[0].$store.dispatch(VUEX_ASSORTMENT_GRID_TREEROWS_UPDATE, params).then(() => {
        })
      }
    }

    if (emitParams.length > 0) {
      // history update
      if (DataMiddleware.isUndoRedo(doReverse)) {
        app.config.globalProperties.emitter.emit('aggrid-event', {
          type: 'treerowsUpdate',
          history: doReverse,
          payload: emitParams
        })
      }
    }
  },

  apiSeasonsManagerRowsUpdate: function (arr, doReverse) {
    let params = []
    let emitParams = []
    let newobj = null

    for (let i = 0; i < arr.length; i++) {
      const obj = arr[i]
      if (obj.oldValue !== obj.newValue) {
        // get field name that was changed
        let fieldChanged = obj.colDef.field

        // check if already added ID, if so, append ID
        let existingObj = params.filter(params => params?.data?.id === obj.data.id)
        let theValue = (doReverse === 'undo') ? obj.oldValue : obj.newValue

        if (existingObj.length === 0) {
          newobj = {
            id: obj.data._id,
            properties: {}
          }
          newobj.properties[fieldChanged] = theValue
          params.push(newobj)

          emitParams.push(newobj)
        } else {
          newobj = existingObj[0]
          newobj.properties[fieldChanged] = theValue
        }
      }// if obj.oldValue !== obj.newValue
    } // loop

    if (params.length > 0) {
      if (DataMiddleware.blockAPICompletely() === false) {
        GridHelpers.mgThisArray[0].$store.dispatch(VUEX_SEASON_UPDATE, params).then(() => {
        })
      }
    }

    if (emitParams.length > 0) {
      // history update
      if (DataMiddleware.isUndoRedo(doReverse)) {
        app.config.globalProperties.emitter.emit('aggrid-event', {
          type: 'treerowsUpdate',
          history: doReverse,
          payload: emitParams
        })
      }
    }
  },

  apiSortableListSortUpdate: function (arr) {
    let rows = []
    for (let i = 0; i < arr.length; i++) {
      let obj = arr[i]
      let newobj = {
        id: obj._id,
        properties: {
          sortOrder: obj.sort
        }
      }
      rows.push(newobj)
    }
    if (rows.length > 0) {
      if (DataMiddleware.blockAPICompletely() === false) {
        GridHelpers.mgThisArray[0].$store.dispatch(VUEX_ASSORTMENT_GRID_TREEROWS_UPDATE, rows).then(() => {
        })
      }
    }
  },

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // DATA MODEL MANIPULATORS
  // these reformat the response data from the assortments state into a usable format by ag grid
  convertOrderToRowData: function (data, order, user) {
    let ret = []
    if (data) {
      for (let i = 0; i < data.length; i++) {
        let obj = cloneDeep(data[i])
        obj = DataMiddleware.convertOrderToRowData_PolishObjectData(obj, order, user)
        ret.push(obj)
      }
    }
    return ret
  },
  convertOrderToRowData_PolishObjectData: function (obj, order, user) {
    let orderType = order.type

    let tid = obj.identifier
    if (!tid) {
      tid = Math.random()
    }
    if (!obj._id) {
      obj._id = tid
    }
    if (!obj.id) {
      obj.id = (obj._id) ? obj._id : tid
    }

    if (!obj.quantity) {
      obj.quantity = 1
    }

    if (!obj.side) {
      if (orderType === ITS__ORDERS__ORDER_TYPE__PROMO) {
        obj.side = 'Pair'
      } else {
        obj.side = 'Any Side'
      }
    }

    if (!obj.source) {
      if (orderType === ITS__ORDERS__ORDER_TYPE__PROMO) {
        obj.source = 'Inventory'
      } else {
        obj.source = 'Sample Library'
      }
    }

    if (!obj.status) {
      obj.status = 'PENDING' // Make sure its upper case

      if (orderType === ITS__ORDERS__ORDER_TYPE__PROMO) {
        const approverIsAlsoRequestor = (user && order && user.fullName === order.requesterName)
        if (approverIsAlsoRequestor) {
          obj.status = 'APPROVED'
        }
      }
    }

    if (orderType === ITS__ORDERS__ORDER_TYPE__SAMPLE) {
      if (!obj.sizePreference) {
        obj.sizePreference = 'Preferred'
      }
    }

    if (!obj.shipper) {
      obj.shipper = 'Pending' // Make sure its title case
    }

    if (!obj.returnType) {
      obj.returnType = 'Keep'
    }

    if (!obj.coordinatorName) {
      const coordinators = properties.state.data.Orders.SAMPLE.properties.coordinatorAssignment
      let defaultCoordinator = coordinators.find(item => item.division === obj.division)
      if (defaultCoordinator?.coordinatorName) {
        obj.coordinatorName = defaultCoordinator.coordinatorName
      }
    }

    if (!obj.trackingUrl) {
      const shipper = obj.shipper
      const trackingId = obj.tracking
      const url = ColumnHelpers.constructTrackingUrl(shipper, trackingId)
      obj.trackingUrl = url
    }

    // default size values if they are missing
    if (!obj.size) {
      let sizeRet = '' // PROMO IS BLANK - SAMPLE USES THE DEFAULT BELOW
      if (orderType === ITS__ORDERS__ORDER_TYPE__SAMPLE) {
        let genderCode = '' // needs to be fetched from project info or order info

        if (obj?.availability) {
          genderCode = obj?.availability?.[0]?.gender || obj.gender
        }

        sizeRet = '9' // DEFAULT!
        if (obj.productType === 'Footwear') {
          switch (genderCode) {
            case undefined:
            case '':
              // leave the default
              break
            case 'IN':
              sizeRet = '7'
              break
            case 'CH':
            case 'BY':
            case 'GR':
            case 'BOYS' :
            case 'GIRLS' :
              sizeRet = '13'
              break
            case 'WN':
            case 'WOMENS' :
              sizeRet = '6'
              break
            case 'NG':
            case 'MN':
            case 'UN':
            case 'MENS' :
            case 'UNISEX' :
            case 'NO GENDER' :
            default:
              sizeRet = '9'
              break
          }
        } else if (obj.productType === 'Apparel') {
          switch (genderCode) {
            case undefined:
            case '':
              // leave the default
              break
            case 'IN':
              sizeRet = 'M'
              break
            case 'CH':
            case 'BY':
            case 'GR':
            case 'BOYS' :
            case 'GIRLS' :
              sizeRet = 'M'
              break
            case 'WN':
            case 'WOMENS' :
              sizeRet = 'M'
              break
            case 'NG':
            case 'MN':
            case 'UN':
            case 'MENS' :
            case 'UNISEX' :
            case 'NO GENDER' :
            default:
              sizeRet = 'L'
              break
          }
        } else {
          // should never happen
          // leave the default
        }
      }
      obj.size = sizeRet
    }

    obj.dataOnLoad = cloneDeep(obj)
    return obj
  },
  convertOrdersToRowData: function (data) {
    let ret = []
    if (data) {
      for (let i = 0; i < data.length; i++) {
        let obj = cloneDeep(data[i])
        obj = DataMiddleware.convertOrdersToRowData_PolishObjectData(obj)
        ret.push(obj)
      }
    }
    return ret
  },
  convertOrdersToRowData_PolishObjectData: function (obj) {
    // make an ID!
    obj.id = obj._id + '-' + Math.random()
    obj.type = 'item' // for list grid
    return obj
  },

  // these reformat the response data from the assortments state into a usable format by ag grid
  convertSampleInventoryToRowData: function (data) {
    let ret = []
    if (data) {
      for (let i = 0; i < data.length; i++) {
        let obj = cloneDeep(data[i])
        obj = DataMiddleware.convertSampleInventoryToRowData_PolishObjectData(obj)
        ret.push(obj)
      }
    }
    return ret
  },
  convertSampleInventoryToRowData_PolishObjectData: function (obj) {
    // make an ID!
    obj.id = obj._id

    return obj
  },

  // these reformat the response data from the assortments state into a usable format by ag grid
  convertLibrariesToRowData: function (data) {
    let ret = []
    if (data) {
      for (let i = 0; i < data.length; i++) {
        let obj = cloneDeep(data[i])
        obj = DataMiddleware.convertLibrariesToRowData_PolishObjectData(obj)
        ret.push(obj)
      }
    }
    return ret
  },
  convertLibrariesToRowData_PolishObjectData: function (obj) {
    // make an ID!
    obj.id = obj._id

    // default primary and working file
    obj.primaryFile = {
      thumbnail: '',
      groups: []
    }
    obj.workingFile = {
      thumbnail: '',
      groups: []
    }
    if (obj.files && obj.files.length > 0) {
      // set primary
      let keyPrimary = obj.files.filter(x => x['usageType'] === 'Primary')
      if (keyPrimary.length) {
        obj.primaryFile = cloneDeep(keyPrimary[0])
        if (!obj.primaryFile.thumbnail) {
          obj.primaryFile.thumbnail = ''
        }
        if (!obj.primaryFile.groups) {
          obj.primaryFile.groups = []
        }
      }

      // set working
      let keyWorkingFile = obj.files.filter(x => x['usageType'] === 'Working File')
      if (keyWorkingFile.length) {
        obj.workingFile = cloneDeep(keyWorkingFile[0])
        if (!obj.workingFile.thumbnail) {
          obj.workingFile.thumbnail = ''
        }
        if (!obj.workingFile.groups) {
          obj.workingFile.groups = []
        }
      }
    }

    // defaults
    let msg = ''
    if (!obj.lang) obj.lang = msg
    if (!obj.year) obj.year = msg
    if (!obj.initiative) obj.initiative = msg
    if (!obj.function) obj.function = msg
    if (!obj.element) obj.element = msg
    if (!obj.category) obj.category = msg
    if (!obj.entity) obj.entity = [msg]
    if (!obj.storeType) obj.storeType = msg
    if (!obj.addedBy) obj.addedBy = msg
    if (!obj.startDate) obj.startDate = msg
    if (!obj.endDate) obj.endDate = msg

    // calculated data
    let dimensions = ''
    if (obj.primaryFile.dimensions && obj.primaryFile.dimensions.width && obj.primaryFile.dimensions.height) {
      dimensions = obj.primaryFile.dimensions.width + 'x' + obj.primaryFile.dimensions.height
    }
    obj.calculated = {
      dimensions: dimensions
    }

    return obj
  },

  // if deeplinking into site, router info is not yet available, so i pass in a payload to see if this is internal
  convertAssortmentDetailsToRowData: function (data, assortment) {
    let ret = []
    if (data) {
      let isInternalAssortment = (assortment?.orgType === ITS__ASSORTMENTS__ORG_TYPE__INTERNAL)
      const options = {
        isInternalAssortment: isInternalAssortment,
        assortment: assortment,
        properties: properties
      }

      for (let i = 0; i < data.length; i++) {
        // polish object data and push
        let obj = cloneDeep(data[i])
        // obj = pricesheet.prepAssortmentDetailDataFromBackend(obj, -1, options)
        obj = pricesheet.prepAssortmentDetailDataFromBackend(obj, -1, options)

        // check pillar, and grab set pillar from other styles from within the grid
        let styleData = GridHelpers.returnMatchingStylesFromAssortment(obj.style, assortment)
        if (styleData.length > 1) {
          obj.pillar = styleData[0].pillar
        }

        obj.type = 'item'

        ret.push(obj)
      }
    }
    return ret
  },

  // convert assortments feed for tree data presentations
  convertAssortmentsToTreeData: function (data, skipTreeDataConvert = false) {
    let ret = []
    if (skipTreeDataConvert) {
      ret = data
    } else {
      let arrayOfFoldersCreated = []
      if (data) {
        for (let i = 0; i < data.length; i++) {
          let obj = data[i]

          // FOLDER UPDATES
          // check for folder hierarchies
          let hierarchy = obj.hierarchy?.slice(0) || []
          if (hierarchy.length > 0) {
            let hierarchyString = hierarchy.join(DataMiddleware.hierarchySeparator)
            let didThisAlready = arrayOfFoldersCreated.includes(hierarchyString)

            // if no hierarchy folder made, then make it
            if (!didThisAlready) {
              let nestedFolderName = ''
              let tempHierarchy = []
              for (let ii = 0; ii < hierarchy.length; ii++) {
                // get folder name, and also nested folder name
                let folderName = hierarchy[ii].toString()
                if (nestedFolderName !== '') {
                  nestedFolderName += DataMiddleware.hierarchySeparator
                }
                nestedFolderName += folderName

                // increment temp hierarchy
                tempHierarchy.push(folderName.toString())

                let didThisAlready2 = arrayOfFoldersCreated.includes(nestedFolderName)
                if (!didThisAlready2) {
                  // this nested folder is new - so make an ID and folder
                  let longDisplayTitle = nestedFolderName.toString().split(DataMiddleware.hierarchySeparator).join(' \\ ')

                  let folderObj = {}
                  folderObj.hierarchy = tempHierarchy.slice(0)
                  folderObj.type = 'folder'
                  // folderObj.id = nestedFolderName.toString()
                  folderObj.id = Math.random()
                  folderObj.title = folderName.toString()
                  folderObj.longDisplayTitle = longDisplayTitle
                  folderObj.updates = 0
                  folderObj.dynamic = 0

                  ret.push(folderObj)
                  arrayOfFoldersCreated.push(nestedFolderName.toString())
                }
              }
            }
          }

          // ITEM UPDATES
          obj.type = 'item'
          let newHierarchy = hierarchy.slice(0)

          // newHierarchy.push(obj._id) // items have their own id in the hierarchy
          newHierarchy.push(obj.title) // items have their own id in the hierarchy

          // let hierarchyString = hierarchy.join(DataMiddleware.hierarchySeparator)
          obj.hierarchy = newHierarchy

          obj.id = obj._id

          if (obj?.method === ITS__ASSORTMENTS__METHOD_TYPE__DYNAMIC) {
            obj.dynamic = 1
          } else {
            obj.dynamic = 0
          }

          ret.push(obj)
        }
      }
    }

    // sort and leave
    ret.sort((a, b) => (a.id > b.id) ? 1 : -1)

    return ret
  },

  // PRODUCT FETCHING RELATED
  productsFetch (styleColors, fetchRelatesToSpecificAssortment = false, t = null) {
    if (styleColors && styleColors.length > 0) {
      if (!t) {
        t = GridHelpers.mgThisArray[0]
      }
      let data = {
        '$or': styleColors,
        _options: {
          totalCount: true,
          sort: { style: 1 }
          /* includeImageData: true,
          includeEcomData: false,
          productDataFormat: {
            withPricing: true
          },
          skipHumanFormat: true */
        }
      }
      // if fetchSpecificToAssortment, use api/assortments/products/query
      if (fetchRelatesToSpecificAssortment) {
        data._uiOptions.assortmentsQuery = {
          channel: t.assortment?.channel,
          orgType: t.assortment?.orgType,
          _id: t.assortment?._id
        }
      }

      // Internet vs Assortments fields
      if (router?.currentRoute?.value.meta?.manageType === ITS__LIBRARIES__MANAGE_TYPE__ASSORTMENTS__INTERNAL) {
        data._options.carryoverDate = t.assortment?.carryoverDate
        data._options.region = t.assortment?.region
        data.productType = t.assortment?.productType
        if (data._options.region === 'Europe') {
          data['locations.code'] = 'EU'
        } else {
          data['locations.code'] = 'US'
        }
      } else {
        data['locations.lineStatus'] = t.assortment?.lineStatus
        data['locations.code'] = t.assortment?.locationId
        data['productType'] = t.assortment?.productType
      }

      let payload = {
        data: data,
        action: 'replace'
      }
      if (styleColors.length > feConstants.ITS__LONG_WAIT_BLOCKER__NUM_OF_ITEMS) longWaitBlocker.show()
      t.$store.dispatch(VUEX_PRODUCTS_FETCH, payload)
    }
  },
  revertProductsFetch (payload) {
    // REVERT CHANGES
    let styleColors = []
    for (let i = 0; i < payload.length; i++) {
      let styleColorFromId = payload[i].id.split('-')
      styleColors.push({
        id: payload[i].id,
        properties: {
          style: styleColorFromId[0],
          color: styleColorFromId[1]
        }
      })
    }
    DataMiddleware.emitRowsUpdate(styleColors, true)
  },

  getNestedIDFromHierarchy: function (hierarchy) {
    let finalId = ''
    if (hierarchy.length > 0) {
      finalId = hierarchy.join(DataMiddleware.hierarchySeparator)
      finalId += DataMiddleware.hierarchySeparator
    }
    return finalId
  },

  convertInternalAssortmentsListToRowData: function (data) {
    let ret = []
    if (data) {
      for (let i = 0; i < data.length; i++) {
        let obj = cloneDeep(data[i])
        obj = DataMiddleware.convertInternalAssortmentsListToRowData_PolishObjectData(obj)
        ret.push(obj)
      }
    }
    return ret
  },
  convertInternalAssortmentsListToRowData_PolishObjectData: function (obj) {
    // make an ID!
    obj.id = obj._id
    obj.type = 'item'

    // set key iniative name
    obj.lookupFilter = generateInternalAssortmentName(obj)
    return obj
  },

  convertManageSeasonsToRowData: function (data) {
    let ret = []
    if (data) {
      for (let i = 0; i < data.length; i++) {
        let obj = cloneDeep(data[i])
        obj = DataMiddleware.convertManageSeasonsToRowData_PolishObjectData(obj)
        ret.push(obj)
      }
    }
    return ret
  },
  convertManageSeasonsToRowData_PolishObjectData: function (obj) {
    // make an ID!
    obj.id = obj._id
    obj.previewJpg = (obj.previewJpg) ? '/' + obj.previewJpg : null
    obj.type = 'item'
    return obj
  },

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // EMIT METHODS
  // emit methods are either triggered by one of 3 things:
  // 1) from an external component,  2) from undo/redo action 3) from AG Grid human manipulation (cell changes, dragging, etc)
  // they deal specifically with updating AG grid and passing data to the back end
  // we need to be careful not to have infinite API update loops from the undo/redo actions
  // so undo/redo actions are flagged to do re-update the API

  // master listener
  emitSwitchboard: function (params) {
    switch (params.type) {
      case 'treerowsUpdate':
        DataMiddleware.emitTreerowsUpdate(params)
        break
      case 'rowsUpdate':
        DataMiddleware.emitRowsUpdate(params)
        break
      case 'rowsUpdateSort':
        GridHelpers.mgThisArray[0].preredrawSnapshot = params.preredrawSnapshot
        DataMiddleware.emitRowsUpdateSort(params)
        break
      case 'rowsDelete':
        DataMiddleware.emitRowsDelete(params)
        break
      case 'rowsAdd':
        DataMiddleware.emitRowsAdd(params)
        break
      case 'rowsAddOrders':
        DataMiddleware.emitRowsAddOrders(params)
        break
      case 'snapshot':
        DataMiddleware.emitSnapshot()
        break
      case 'emitChangeLabelColor':
        DataMiddleware.emitChangeLabelColor(params)
        break
      case 'clearLabelColor':
        DataMiddleware.emitClearLabelColor(params)
        break
      case 'excelImporterClose':
        ExcelMiddleware.reinit()
        GridHelpers.restoreMasterGridThis()
        break
      default:
        d.log('Invalid emitSwitchboard type: ' + params.type)
        break
    }
  },

  // update row column values
  emitTreerowsUpdate: function (params) {
    const arr = (params.payload) ? params.payload : params
    for (let z = 0; z < GridHelpers.mgThisArray.length; z++) {
      const gridApi = GridHelpers.mgThisArray[z].gridApi
      for (let i = 0; i < arr.length; i++) {
        const obj = arr[i]
        const id = obj.id
        const node = gridApi.getRowNode(id)
        if (node) {
          for (let key in obj.properties) {
            // do it both ways because of ag grid bug
            node.setDataValue(key, obj.properties[key])
            node.data[key] = obj.properties[key]
            node.data.SKIPAPIHISTORY = true
          }
        }
      }

      // call refresh becauase tree data doesn't trigger onCellValueChanged automatically for some reason
      // but dont do it on gridSynch events
      if (params.history !== 'gridSynch') {
        gridApi.clearFocusedCell()
      }
      gridApi.refreshCells()

      // send to back end
      // dont do this, because setDataValue triggers onCellValueChanged, which then calls DataMiddleware.addNewChange
    }
  },

  // update row column values
  emitRowsUpdate: function (params, forceSkipApiHistory = false) {
    let arr = (params.payload) ? params.payload : params

    let didComplicatedPriceColumns = false // only update complicated price columns once - because one triggers all three
    for (let z = 0; z < GridHelpers.mgThisArray.length; z++) {
      let gridApi = GridHelpers.mgThisArray[z].gridApi
      for (let i = 0; i < arr.length; i++) {
        let obj = arr[i]

        // grab id and properties
        let id = obj.id
        let properties = obj.properties
        if (!id && obj.frontEnd) {
          id = obj.frontEnd.id
        }
        if (!properties && obj.frontEnd) {
          properties = obj.frontEnd.properties
        }

        // get node
        let node = gridApi.getRowNode(id)
        if (node) {
          // loop through properties
          for (let key in properties) {
            // thumbnail is conjoined. And has a different way of updating, outside of the usual change.
            // this is due to the fact that their updates come from a flyout
            // also skip previewJpg (which occurs in seasons manager
            if (key === 'thumbnail') {
              node.data.SKIPAPIHISTORY = (params.history)
            } else {
              node.data.SKIPAPIHISTORY = true
            }
            // override if forced
            if (forceSkipApiHistory) {
              node.data.SKIPAPIHISTORY = true
            }

            // these three values - only do one of them since it updates the others
            // node.setDataValue(key, obj.properties[key])
            if (key === 'discountAmount' || key === 'discountPercent' || key === 'netClose') {
              if (!didComplicatedPriceColumns) {
                didComplicatedPriceColumns = true
                // try catch this, because sometimes columns aren't in the visual front end
                try {
                  node.setDataValue(key, properties[key])
                } catch {
                }
              }
            } else {
              // try catch this, because sometimes columns aren't in the visual front end
              try {
                node.setDataValue(key, properties[key])
              } catch {
              }
              // if clearing value, also clear sourceFile
              if (key === 'previewJpg' && !properties[key]) {
                node.setDataValue('sourceFile', '')
              }
            }
          }// for properties
        }// if node
      }// for arr
    } // mgThisArray

    // normally send to back end
    // dont do this, because setDataValue triggers onCellValueChanged, which then calls DataMiddleware.addNewChange
  },

  // delete rows
  emitRowsDelete: function (params) {
    let arr = (params.payload) ? params.payload : params
    arr = cloneDeep(arr)

    // refresh front end grids
    for (let z = 0; z < GridHelpers.mgThisArray.length; z++) {
      let gridApi = GridHelpers.mgThisArray[z].gridApi
      let type = GridHelpers.mgThisArray[z].type

      if (GridHelpers.mgThisArray[0].isLibrariesGrid) {
        // splice out cache and refresh
        // TODO - this is a great idea that doesn't quite work server side...deleting rows
        /*
        for (let i = 0; i < arr.length; i++) {
          let row = arr[i]
          let node = gridApi.getRowNode(row.id)

          // node.data.SKIPAPIHISTORY = true
          // node.data.isRowHidden = true
        }
        // gridApi.redrawRows() // refreshes the visual front end
         */

        GridHelpers.deselectAll()
      } else {


        if (type === 'orders-detail') {
          let rows = GridHelpers.getRowNodes();
          arr.forEach(item => {
            // Find the corresponding row in rows
            let matchingRow = rows.find(row => row.data.identifier === item.identifier);

            // Step 3: Update the id and _id of the item in arr with the values from the matched row
            if (matchingRow) {
              item.id = matchingRow.data.id;
              item._id = matchingRow.data._id;
            }
          })
        }
        gridApi.applyTransaction({ remove: arr })

        if (type === 'orders-detail') {
          GridHelpers.mgThisArray[z].triggerSubmitNewSampleButtonChange()
        }
      }

      // refresh
      gridApi.refreshCells()
    } // for z

    // send to back end - but just once
    if (!params.history) {
      DataMiddleware.addNewChange(cloneDeep(arr), 'rowsDelete')
    }
  },

  // add new rows
  emitRowsAdd: function (params) {
    let arr = (params.payload) ? params.payload : params

    for (let z = 0; z < GridHelpers.mgThisArray.length; z++) {
      const gridApi = GridHelpers.mgThisArray[z].gridApi
      const type = GridHelpers.mgThisArray[z].type

      // parse data correctly
      if (type === 'assortment-details') {
        arr = DataMiddleware.convertAssortmentDetailsToRowData(arr, GridHelpers.mgThisArray[0].assortment)
      }

      for (let i = 0; i < arr.length; i++) {
        // get ID of new row
        let newRow = arr[i]
        let tempId = null
        if (type === 'assortment-details') {
          tempId = newRow.style + '-' + newRow.color
        } else {
          tempId = newRow.id
        }

        if (newRow && tempId) {
          // CHECK FOR DUPLICATE - add only if not already there
          let node = gridApi.getRowNode(tempId)
          if (!node) {
            let res = gridApi.applyTransaction({ add: [newRow] })

            // now that it's added, scroll to it
            let node = res.add[0]
            // gridApi.ensureNodeVisible(node, 'middle')
            gridApi.ensureIndexVisible(node?.rowIndex, 'middle')

            // start editing new title cell in my assortments data
            if (type === 'assortments-list') {
              // AG 23.1.0 GRID IS BUGGY
              // startEditingCell used to work for autogrouped columns
              // now it does not
              gridApi.setFocusedCell(node.rowIndex, 'ag-Grid-AutoColumn', null)
              gridApi.startEditingCell({
                rowIndex: node.rowIndex,
                colKey: 'title', // 'title',//ag-Grid-AutoColumn
                rowPinned: null,
                keyPress: null,
                charPress: null
              })
            }
          }
        }
      }

      // send to back end
      if (!params.history) {
        DataMiddleware.addNewChange(cloneDeep(arr), 'rowsAdd')
      }

      // REFRESH
      GridHelpers.refreshSortModel(true)
    }
  },

  // add new rows
  emitRowsAddOrders: function (params) {
    let arr = (params.payload) ? params.payload : params

    for (let z = 0; z < GridHelpers.mgThisArray.length; z++) {
      const gridApi = GridHelpers.mgThisArray[z].gridApi
      const order = GridHelpers.mgThisArray[z].order
      const user = GridHelpers.mgThisArray[z].user
      arr = DataMiddleware.convertOrderToRowData(arr, order, user)
      for (let i = 0; i < arr.length; i++) {
        // get ID of new row
        let newRow = arr[i]
        let tempId = newRow.id
        if (newRow && tempId) {
          // CHECK FOR DUPLICATE - add only if not already there
          // let node = gridApi.getRowNode(tempId)
          // if (!node) {
            let res = gridApi.applyTransaction({
              add: [newRow],
              addIndex: 0
            })

            // now that it's added, scroll to it
            let node = res.add[0]
            // gridApi.ensureNodeVisible(node, 'middle')
            gridApi.ensureIndexVisible(node?.rowIndex, 'middle')

            // trigger new change
            GridHelpers.mgThisArray[z].triggerSubmitNewSampleButtonChange()
          // }
        }
      }

      // send to back end
      if (!params.history) {
        DataMiddleware.addNewChange(cloneDeep(arr), 'rowsAdd')
      }

      // REFRESH
      GridHelpers.refreshSortModel(true)
    }
  },

  // update sort
  emitRowsUpdateSort: function (params) {
    let rows = params.payload
    let doHardReset = params.doHardReset
    for (let z = 0; z < GridHelpers.mgThisArray.length; z++) {
      let gridApi = GridHelpers.mgThisArray[z].gridApi
      let assortment = GridHelpers.mgThisArray[z].assortment
      let t = GridHelpers.mgThisArray[z]

      if (doHardReset) {
        // update and remove
        // if group is enabled, do the janky version: remove and then update
        // as of 4-2-2020 - always do it - ag grid latest version doesnt like row update
        // but now as of 6-2-2020 - the latest ag grid seems fine with using update, so i'll leave it off
        // 6-4-2020  - strike that, turning back on
        // but now as of 4-2-2021, - the latest ag grid seems fine with using update, so doHackyVersion = false

        // do hacky version if we are jumping out of a column sort
        let sortState = gridApi.getColumnState()
        let columnsSorted = sortState.filter(x => x['sort'] !== null)
        let doHackyVersion = (columnsSorted.length > 0)
        doHackyVersion = false

        if (doHackyVersion) {
          let updateArr = []
          for (let i = 0; i < rows.length; i++) {
            let obj = cloneDeep(rows[i])
            updateArr.push(obj)
          }
          gridApi.applyTransaction({ remove: updateArr })
          setTimeout(function () {
            let updateArr = []
            for (let i = 0; i < rows.length; i++) {
              let obj = cloneDeep(rows[i])
              updateArr.push(obj)
            }
            gridApi.applyTransaction({ add: updateArr })
          }, 200)
        } else {
          let updateArr = []
          for (let i = 0; i < rows.length; i++) {
            let obj = cloneDeep(rows[i])
            updateArr.push(obj)
          }
          gridApi.applyTransaction({ update: updateArr })
        }

        // check and see if we need to expand or unexpand, line by line
        // NOTE: if you ever see issues with this note working, try the timeout
        setTimeout(t.determineExpandUnexpand, 400)
      } else {
        // set row data
        if (assortment.method !== ITS__ASSORTMENTS__METHOD_TYPE__STATIC) {
          gridApi.setGridOption('rowData', rows)
        }
      }

      // refresh
      gridApi.refreshCells()
      gridApi.redrawRows()

      // send to back end
      if (!params.history) {
        DataMiddleware.addNewChange(rows, 'rowsUpdateSort')
      }
    }
  },

  // get a snapshot of row data
  emitSnapshot: function () {
    if (DataMiddleware.blockAPICompletely() === false) {
      for (let z = 0; z < GridHelpers.mgThisArray.length; z++) {
        // let gridApi = GridHelpers.mgThisArray[z].gridApi
        let t = GridHelpers.mgThisArray[z]

        const manageType = router.currentRoute.value.meta.manageType

        switch (manageType) {
          case 'AssortmentsRegular' :
          case 'AssortmentsInternal' :
            let assortment = GridHelpers.mgThisArray[z].assortment

            if (assortment.method === ITS__ASSORTMENTS__METHOD_TYPE__DYNAMIC) {
              // FYI: Grid Snapshots do not work with DynamicAssortments
            } else {
              let rowNodes = GridHelpers.getRowNodes()
              let retData = GridHelpers.extractRowDataFromRowNodes(rowNodes)
              t.$store.dispatch(VUEX_ASSORTMENT_GRID_SET_SNAPSHOT, retData).then(() => {
                // done
              })
            }
            break

          case 'PromoDetail' :
          case 'SampleDetail' :
            let rowNodes = GridHelpers.getRowNodes()
            let retData = GridHelpers.extractRowDataFromRowNodes(rowNodes)
            t.$store.dispatch(VUEX_ORDERS_GRID_SET_SNAPSHOT, retData).then(() => {
              // done
            })
            break
        }
      }
    }
  },

  emitClearLabelColor: function (params) {
    let labelID = params.payload.labelID
    if (labelID) {
      let rowNodes = GridHelpers.getRowNodes()
      for (let i = 0; i < rowNodes.length; i++) {
        let node = rowNodes[i]

        if (node.group === false) {
          if (node.data.label === labelID) {
            node.setDataValue('label', '')
          }
        }
      }
    }
  },
  emitChangeLabelColor: function (params) {
    let oldLabelID = params.payload.oldLabelID
    let labelID = params.payload.labelID
    if (oldLabelID && labelID) {
      let rowNodes = GridHelpers.getRowNodes()
      for (let i = 0; i < rowNodes.length; i++) {
        let node = rowNodes[i]
        if (node.group === false) {
          if (node.data.label === oldLabelID) {
            node.setDataValue('label', labelID)
          }
        }
      }
    }
  },

  /// /////////////////////////////////////////////////////////
  // SORT
  // pass an array of sorted columns
  // do special parsing as needed
  parseAssortmentSort (sortState, backendToFront = false, dynamicQuery = false) {
    let newSort = (dynamicQuery) ? {} : []
    sortState = cloneDeep(sortState)
    sortState.sort((a, b) => a.sortIndex - b.sortIndex)
    let sortIndexCounter = 0
    for (let i = 0; i < sortState.length; i++) {
      let obj = sortState[i]
      let dir = obj.sort
      let colId = obj.colId

      // we remap some columns - because sometimes the back end needs them to be named differently,
      // but we don't want to change the names in how they are referenced on the front end
      // This logic is in TWO PLACES - search dynamicColumnRemappingConfig
      if (backendToFront) {
        if (colId === 'styleNumeric' && GridHelpers.mgThisArray[0].assortment.productType !== ITS__PRODUCT_TYPE__APPAREL) {
          colId = 'style'
        }
        if (dynamicQuery) {
          if (colId === 'locations.wholesalePrice') {
            colId = 'cost'
          } else if (colId === 'locations.suggestedRetail') {
            colId = 'suggestedRetail'
          }
        }
      } else {
        if (colId === 'style' && GridHelpers.mgThisArray[0].assortment.productType !== ITS__PRODUCT_TYPE__APPAREL) {
          colId = 'styleNumeric'
        }
        if (dynamicQuery) {
          if (colId === 'cost') {
            colId = 'locations.wholesalePrice'
          } else if (colId === 'suggestedRetail') {
            colId = 'locations.suggestedRetail'
          }
        }
      }

      // also, dynamic queries use a numeric style of reference for asc/desc
      if (dynamicQuery) {
        dir = (dir === 'asc') ? 1 : -1
        newSort[colId] = dir
      } else {
        let sortIndex = (backendToFront) ? i : obj.sortIndex
        newSort[sortIndex] = {
          colId: colId,
          sort: dir,
          sortIndex: sortIndexCounter
        }
      }
      sortIndexCounter++
    }
    return newSort
  }

}
export default DataMiddleware
